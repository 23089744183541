<template>
  <header class="header">
    <div class="header-content">
      <a href="/" class="header-content__container-logo">
        <img class="brand-header__img" :src="urlImage" alt="altImage"/>
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",

  data() {
    return {
      urlImage: require("../assets/images/logo.png"),
      altImage: "Bet Club",
    };
  },
};
</script>
